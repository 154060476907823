<template>
  <div class="panel">
    <div>
      <h1>Бүсийн цагийн хуваарь</h1>
      <div style="display:flex;">
        <!-- <el-select
          v-model="type"
          size="small"
          class="ml10"
          placeholder="Select"
        >
          <el-option
            v-for="(typing, index) in types"
            :key="index"
            :label="typing.name"
            :value="typing.id"
          >
          </el-option>
        </el-select> -->
        <el-radio-group
          class="mr10"
          size="small"
          v-model="zone"
          @change="getPredictionByWeek"
        >
          <el-radio-button label="CENTRAL_ZONE">Төвийн бүс</el-radio-button>
          <el-radio-button label="SOUTH_ZONE">Хан-Уул бүс</el-radio-button>
          <el-radio-button label="YARMAG_ZONE">Яармаг бүс</el-radio-button>
        </el-radio-group>
        <el-date-picker
          type="date"
          placeholder="Эхлэх огноо"
          v-model="start_date"
          class="mb10"
          size="small"
          :picker-options="pickerOptions"
        ></el-date-picker>
        <el-date-picker
          type="date"
          placeholder="Дуусах огноо"
          v-model="end_date"
          class="mb10 ml5"
          size="small"
          :picker-options="pickerOptions"
        ></el-date-picker>
        <div>
          <el-button
            size="small"
            class="ml10"
            type="primary"
            icon="el-icon-search"
            v-loading.fullscreen.lock="loading"
            @click="getPredictionByWeek"
            >Хайх</el-button
          >
        </div>
      </div>
      <div v-if="isSearch">
        <div style="display:flex; float:right;">
          <div class="info ml15">
            <div
              style="background-color:#67c244; width:10px; height:10px; border-radius:20px"
            ></div>
            <span class="ml5">Хэвийн</span>
          </div>
          <div class="info ml15">
            <div
              style="background-color:#f9ae56; width:10px; height:10px; border-radius:20px"
            ></div>
            <span class="ml5">Дундаж</span>
          </div>
          <div class="info ml15">
            <div
              style="background-color:#fc6d85; width:10px; height:10px; border-radius:20px"
            ></div>
            <span class="ml5">Ачаалал ихтэй</span>
          </div>
        </div>
        <table style="width:100%;">
          <thead>
            <tr>
              <th>Өдөр</th>
              <th
                style="padding:0 2%; width:160px;"
                v-for="(week, dayIndex) in weeks"
                :key="dayIndex"
              >
                {{ week }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pred, predIndex) in prediction" :key="predIndex">
              <td style="padding:0 2%; width:120px;">{{ pred.day }}</td>
              <td v-for="(week, weekIndex) in weeks" :key="weekIndex">
                <div v-for="(sched, schedIndex) in schedule" :key="schedIndex">
                  <div
                    v-if="
                      JSON.stringify(sched.hours) === JSON.stringify(week) &&
                        sched.day === pred.day
                    "
                  >
                    <div
                      v-for="(time, timeIndex) in pred.times"
                      :key="timeIndex"
                      :style="
                        JSON.stringify(time.hours) === JSON.stringify(first)
                          ? time.prediction_count < 11
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 21 &&
                              time.prediction_count > 10
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) ===
                            JSON.stringify(second)
                          ? time.prediction_count < 6
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 11 &&
                              time.prediction_count > 5
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) === JSON.stringify(third)
                          ? time.prediction_count < 36
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 71 &&
                              time.prediction_count > 35
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) ===
                            JSON.stringify(fourth)
                          ? time.prediction_count < 86
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 171 &&
                              time.prediction_count > 85
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) === JSON.stringify(fifth)
                          ? time.prediction_count < 61
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 121 &&
                              time.prediction_count > 60
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) === JSON.stringify(sixth)
                          ? time.prediction_count < 131
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 261 &&
                              time.prediction_count > 130
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : JSON.stringify(time.hours) ===
                            JSON.stringify(seventh)
                          ? time.prediction_count < 41
                            ? 'background-color:#67c244;'
                            : time.prediction_count < 81 &&
                              time.prediction_count > 40
                            ? 'background-color:#f9ae56;'
                            : 'background-color:#fc6d85;'
                          : ''
                      "
                    >
                      <div
                        v-if="
                          JSON.stringify(time.hours) ===
                            JSON.stringify(sched.hours)
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div
                            style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                          >
                            <span>Хуваарьт цагт</span>
                            <el-input
                              style="width:100%;"
                              :disabled="time.driver_count > 0"
                              size="mini"
                              v-model="sched.additional_price"
                            />
                          </div>
                          <div
                            style="display: flex; flex-direction: column; gap: 5px; width: 100%; background-color: #fff; font-size: 10px"
                          >
                            <span>Нэмэлт</span>
                            <el-input
                              style="width:100%;"
                              :disabled="time.driver_count > 0"
                              size="mini"
                              v-model="sched.planned_additional_price"
                            />
                          </div>
                        </div>
                        <div
                          style="display:flex; justify-content:space-between; margin: 3px 8%;"
                        >
                          <span>Ж: {{ time.driver_count }}</span>
                          <span>Т: {{ time.prediction_count }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-button
          @click="save"
          style="display:flex; justify-items: end;"
          type="success"
          >Хадгалах</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import services from "../../helpers/services";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import "dayjs/locale/mn";
dayjs.locale("mn");
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs().tz("Asia/Ulaanbaatar");
export default {
  created() {
    this.createIncentives();
  },
  methods: {
    async getPredictionByWeek() {
      if (
        ["", null, undefined].includes(this.start_date) ||
        ["", null, undefined].includes(this.end_date)
      ) {
        return this.$notify.error({
          title: "Error",
          message: "Он сар оруулна уу!"
        });
      } else {
        this.loading = true;
        const payload = {
          start_date: dayjs(this.start_date)
            .tz("Asia/Ulaanbaatar")
            .format("YYYY-MM-DD"),
          end_date: dayjs(this.end_date)
            .tz("Asia/Ulaanbaatar")
            .format("YYYY-MM-DD"),
          delivery_zone: this.zone
        };
        await services.getPredictionByWeek(payload).then(prediction => {
          if (prediction.data.status === "success") {
            this.prediction = prediction.data.data;
            this.createIncentives();
            this.isSearch = true;
          }
          this.loading = false;
        });
      }
    },
    createIncentives() {
      this.loading = true;
      this.schedule = [
        {
          day: "Monday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Monday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Tuesday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Wednesday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Thursday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Friday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Saturday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [0, 1, 2, 3, 4, 5],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [6, 7, 8, 9],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [10, 11],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [12, 13, 14],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [15, 16],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [17, 18, 19, 20],
          additional_price: 0,
          planned_additional_price: 0
        },
        {
          day: "Sunday",
          hours: [21, 22, 23],
          additional_price: 0,
          planned_additional_price: 0
        }
      ];
      let hours = [];
      let days = [];
      this.weeks = [];
      this.days = [];
      this.schedule.map(sched => {
        if (!hours.includes(JSON.stringify(sched.hours)))
          hours.push(JSON.stringify(sched.hours));
        if (!days.includes(sched.day)) days.push(sched.day);
      });
      hours.forEach(el => this.weeks.push(JSON.parse(el)));
      days.map(el => this.days.push(el));
      this.loading = false;
    },
    save() {
      const payload = {
        type: "additional",
        start_date: dayjs(this.start_date)
          .tz("Asia/Ulaanbaatar")
          .format("YYYY-MM-DD"),
        end_date: dayjs(this.end_date)
          .tz("Asia/Ulaanbaatar")
          .format("YYYY-MM-DD"),
        delivery_zone: this.zone,
        schedule: this.schedule
      };
      services.addIncentives(payload).then(res => {
        if (res.data.status === "success") {
          this.$message({
            type: "success",
            message: "Амжилттай үүслээ..."
          });
          this.$router.push({
            name: "IncentivesList"
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    }
  },
  data() {
    return {
      isSearch: false,
      loading: false,
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: "",
      zone: "CENTRAL_ZONE",
      prediction: [],
      weeks: [],
      days: [],
      type: "",
      types: [
        {
          name: "Үндсэн",
          id: "default"
        },
        {
          name: "Нэмэлт",
          id: "additional"
        }
      ],
      schedule: [],
      first: [0, 1, 2, 3, 4, 5],
      second: [6, 7, 8, 9],
      third: [10, 11],
      fourth: [12, 13, 14],
      fifth: [15, 16],
      sixth: [17, 18, 19, 20],
      seventh: [21, 22, 23],
      zoneType: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    };
  }
};
</script>
<style scoped>
.image {
  width: 120px;
  height: 100px;
}
.info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
table thead tr th {
  background-color: #e0e0e0;
  font-weight: normal;
  padding: 2% 10px;
}
table tbody tr td {
  border: 1px solid #e0e0e0;
  text-align: center;
  padding: 5px 0px;
}
table tbody tr td.hour-td {
  background-color: #e0e0e0;
  padding: 5px 5px;
}
</style>
